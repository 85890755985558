import React, { Component } from "react";
import IRLayout from "../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
class PaymentThankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: null
    };
  }
  componentDidMount() {
    this.setState(this.props.location.state.values);
  }
  render() {
    return (
      <IRLayout>
        <div className="section white">
          <Container>
            <Col xs={12}>
              <h1 className="centered text-padding">Thank You</h1>
            </Col>
          </Container>
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <Card bg="primary" text="white">
                  <Card.Header>Successful Booking</Card.Header>
                  <Card.Body>
                    <Card.Title style={{ color: "white" }}>
                      You've booked a Chemistry Session with
                    </Card.Title>
                    <Card.Text>{this.state.consultantName}</Card.Text>
                    <Card.Text>Total: Free</Card.Text>

                    <Card.Title style={{ color: "white" }}>
                      Your suggested dates:
                    </Card.Title>
                    <Card.Text>{this.state.scheduledDate1}</Card.Text>
                    <Card.Text>{this.state.scheduledDate2}</Card.Text>
                    <Card.Text>{this.state.scheduledDate3}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <h3>What happens next?</h3>
                <p className="lead" style={{ fontSize: "1.1em" }}>
                  We have received your order and will now pass to the
                  consultant. We will then liase with you both to select a
                  mutually acceptable time for the chemistry session.
                </p>
                <p className="lead" style={{ fontSize: "1.1em" }}>
                  Once we have agreed on a time we will send you a url to our
                  web conference software where you can login and conduct the
                  consultation.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </IRLayout>
    );
  }
}

export default PaymentThankYou;
